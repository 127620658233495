import Call from "./Call";

  const Addcarts = async (data) => {
    let d = await Call({
      path: "api/users/addToCart",
      method: "POST",
      data,
    });
    return d;
  };

  const CartList = async (data) => {
    let d = await Call({
      path: "api/users/cartList",
      method: "POST",
      data,
    });
    return d;
  };

  const UpdateCart = async (data) => {
    let d = await Call({
      path: "api/users/cartUpdate",
      method: "POST",
      data,
    });
    return d;
  };

  const removeCart = async (data) => {
    let d = await Call({
      path: "api/users/removecart",
      method: "POST",
      data,
    });
    return d;
  };

  const couponApplay = async (data) => {
    let d = await Call({
      path: "api/users/couponApplay",
      method: "POST",
      data,
    });
    return d;
  };

  const MakePurchase = async (data) => {
    let d = await Call({
      path: "api/users/placeorder",
      method: "POST",
      data,
    });
    return d;
  };

  export default {
    CartList,
    Addcarts,
    UpdateCart,MakePurchase,
    removeCart,couponApplay
  };