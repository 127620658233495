import React, { Component } from 'react'
import Footer from "../../template/Footer";
import Header from "../../template/Header";
import img3 from "../../../assets/images/car_service_provider/img_3.png";
import WashServices from "../../../services/Services";
import { faTruckLoading } from "@fortawesome/free-solid-svg-icons";

class CarsService extends Component {

  constructor(props) {
    super(props);

    this.state = {
        isshow:false,
        carService: [],
    };
  }

  componentDidMount() {
    this.CarServicesProvider();
  }

  CarServicesProvider() {
    try {
      WashServices.servicesProvider().then((response) => {
        console.log(response);
        this.setState({carService: response.data,isshow:true,  });
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {

const { isshow,carService } = this.state


    return (
     <>
      <Header />
      <section className="sec_main" id="carservice">
        <div className="container">
          <div>
            <div className="sec_title">
              <h4>Car Services Provider</h4>
            </div>

            <div className="sec_subtitle">
              <p>
                Find the best service packages for your car.Select from a wide
                range of car services,from general service, accidental repair to
                custom repairs,etc...
              </p>
            </div> 
              <div className="row">
                {carService.map((data) => {
                  return (
                    <div className="col-md-6 col-lg-3" key={data._id}>
                      <div className="list_box_cars shadow-lg bg-body rounded">
                        {data.photo_reference ? (
                          <img
                            src={
                              process.env.REACT_APP_IMAGE_URL +
                              data.photo_reference
                            }
                            alt="img"
                            className="w-100"
                          />
                        ) : (
                          <img src={img3} alt="img" className="w-100" />
                        )
                        }

                        <div className="list_box_text pt-4">
                          <h5>{data.name}</h5>
                          <p>{data.area}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            <div>
              {/* <button type="button" className="btn btn-outline-primary">E</button> */}
            </div>
          </div>
        </div>
      </section>
      {isshow && <Footer />}
     </>
    )
  }
}
export default CarsService
