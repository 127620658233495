import React, { Component } from 'react'
import img1 from "../../../assets/images/car_provider/img_1.png";
import img2 from "../../../assets/images/car_provider/img_2.png";
import img3 from "../../../assets/images/car_provider/img_3.png";
import img4 from "../../../assets/images/car_provider/img_4.png";
import Footer from "../../template/Footer";
import Header from "../../template/Header";

export default class CarProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isshow:false,
        CarService:[]
    };
  }

  componentDidMount() {
    this.CarService();
  }

  CarService() {
    try {
      const CarService = [{ img: img1 },
      { img: img2 },
      { img: img3 },
      { img: img4 },
      { img: img2 },
      { img: img3 },
      { img: img4 },
      { img: img3 },

    ]
      this.setState({CarService:CarService})
    } catch (error) {
      console.log(error);
    }
  }
  render() {
const { CarService,isshow } = this.state
    return (
      <>
      <Header />
      <section className="sec_main" id="carprovider">
        <div className="container">
          <div className="car_provider">
            <div className="sec_title">
              <h4>Car Buy/Sale Provider</h4>
            </div>

            <div className="sec_subtitle">
              <p>
                Planning to buy a used car or sell your old car? Here best
                provider is best place to buy top certified used cars, Find best
                valuation for your old car & sell at...
              </p>
            </div>

            <div className="row">
              {CarService.map((data, id) => {
                return (
                  <div className="col-md-6 col-lg-3" key={id}>
                    <div className="list_box_cars_provider">
                      <img
                        src={data.img}
                        className="w-100 h-100"
                        alt="location"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      {isshow && <Footer />}
    </>
    )
  }
}
