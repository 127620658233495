import React, { Component } from 'react'
import Footer from '../../template/Footer'
import Header from '../../template/Header'
import CarAccessoriesService from '../../../services/CarAccessories'
import Auth from '../../../services/CheckToauth'
import AddTocartService from '../../../services/AddTocart'
import service_img from "../../../assets/images/car_service_provider/img_3.png";
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify';


class Car_Accessories extends Component {

  constructor(props) {
    super(props);
    this.state = {
          countShopping:'',
          isshow:false,
          CarAccessoriesData: [],
        };
      }
    
      componentDidMount() {
        this.get_CarAccessories();
      }
    
      get_CarAccessories() {
        try {
          CarAccessoriesService.getData().then((response) => {
            this.setState({CarAccessoriesData: response.carAccessories,isshow:true,  });
          });
        } catch (error) {
          console.log(error);
        }
      }

      addTocart(values){
        let checkAuth = Auth();
        if(checkAuth) {
          this.addcart(values)
        } else {
          this.props.history.push("/login");
        }
      }


  addcart(values){
    const fmData = new FormData();
    fmData.append("userId", localStorage.getItem('userId'));
    fmData.append("productId", values["_id"]);
    fmData.append("quantity", 1);
    fmData.append("price", values["price"]);
    AddTocartService.Addcarts(fmData)
    .then(response =>{
      if (response.status === true) {
        this.setState({countShopping:response.result + 1})
        // this.props.history.push("/cart")
      //  window.location.href="/cart";
        toast.success(response.message)
      }else{
        toast.error(response.message)
      }
    })

  }    


  render() {
    const { isshow,CarAccessoriesData } = this.state

    return (
        <>
        <Header countShopping={this.state.countShopping} />
        <section className='sec_main' >
            <div className='container'>
                <div className='car_accessories'>
                    <div className='sec_title'>
                        <h4>Car Accessories</h4>
                    </div>

                    <div className="sec_subtitle">
                        <p></p>
                    </div>

                    <div className="row">
                        {CarAccessoriesData.map((data, id) => {
                            return (
                                    <div className="col-md-6 col-lg-3" key={id}>
                                        <div className="list_box_cars_accessories shadow-lg bg-body rounded">
                                            {data.image ? (
                                                <img src={ process.env.REACT_APP_STORAGE_URL+ data.image }
                                                alt="img" className="w-100" />
                                            ) : (
                                                <img src={service_img} alt="img" className="w-100" />
                                            )}
                                            <div className="list_car_access_text pt-3">
                                                <h5>{data.name}</h5>
                                                <p className="city_area">{data.description}</p>
                                                <h5>INR {data.price}</h5>
                                            </div>
                                            <div className='add_to_cart'>
                                                <button type="button" onClick={() => this.addTocart(data)}>Add to cart</button>
                                                <button type="button" >Buy now</button>
                                            </div> 
                                        </div>
                                    </div>
                                    )
                            })}
                    </div>
                </div>
            </div>
        </section>
        {isshow && <Footer />}
    </>
    )
  }
}
export default withRouter(Car_Accessories)