import Call from "./Call";

const servicesProvider = async () => {
  let d = await Call({
    path: "api/admin/servicesProvider",
    method: "GET",
  });
  return d;
};


const washProvider = async () => {
    let d = await Call({
      path: "api/admin/carwasherProviders",
      method: "GET",
    });
    return d;
  };


export default {servicesProvider,washProvider}