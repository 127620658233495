import React, { Component } from 'react'
import WashServices from "../../../services/Services";
import Footer from "../../template/Footer";
import Header from "../../template/Header";
import service_img from "../../../assets/images/car_service_provider/img_3.png";

export default class CarWashProvider extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isshow: false,
      carWashData: [],
    };
  }

  componentDidMount() {
    this.carWashData();
  }

  carWashData() {
    try {
      WashServices.washProvider().then((response) => {
        this.setState({ carWashData: response.data, isshow: true, });
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {

    const { isshow, carWashData } = this.state


    return (
      <>
        <Header />
        <section className="sec_main" id="carwash">
          <div className="container">
            <div className="car_wash_provider">
              <div className="sec_title">
                <h4>Car Wash Provider</h4>
              </div>

              <div className="sec_subtitle">
                <p>
                  A car wash, carwash, or auto wash is a facility used to clean
                  the exterior, and in some cases the interior of motor vehicles.
                  Car washes can be self-service, full-service, or fully
                  automated.
                </p>
              </div>

              {
                (<div className="row">
                  {carWashData.map((data, id) => {
                    return (
                      <div className="col-md-6 col-lg-3" key={id}>
                        <div className="list_box_cars_wash shadow-lg bg-body rounded">
                          {data.photo_reference ? (
                            <img
                              src={
                                process.env.REACT_APP_IMAGE_URL +
                                data.photo_reference
                              }
                              alt="img"
                              className="w-100"
                            />
                          ) : (
                            <img src={service_img} alt="img" className="w-100" />
                          )}
                          <div className="list_car_wash_text pt-3">
                            <h5>{data.name}</h5>
                            <p className="city_area">{data.area}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>)
              }
            </div>
          </div>
        </section>
        {isshow && <Footer />}
      </>
    )
  }
}
