import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'


var firebaseConfig = {
    apiKey: "AIzaSyCuNp__U0HHtgnpuHUz91CQE6unoalz_Fs",
    authDomain: "carwash-2d09a.firebaseapp.com",
    projectId: "carwash-2d09a",
    storageBucket: "carwash-2d09a.appspot.com",
    messagingSenderId: "787403621913",
    appId: "1:787403621913:web:b3175c0a08048ff9018c88"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  
export default firebase