import React, { Component } from 'react'
import LoginService from '../../../services/auth'
import Logo from "../../../assets/images/logo.png";
import { toast } from 'react-toastify';
import firebase from '../../../firebase'
import { withRouter } from "react-router";


class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      NumberInput: true,
      OtpSend: false,
      phoneNo: "",
      otpcode:'',
    }
  }

  configureCaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        this.onSignInSubmit();
      },
      defaultCountry: "IN"
    });
  }

  handleInputChanged(event) {
    this.setState({
      phoneNo: event.target.value
    });
  }

  // otp send
  handleInputChangedOtp(event) {
    this.setState({
      otpcode: event.target.value
    });
  }

  onSignInSubmit() {
    this.configureCaptcha()
    const phoneNumber = "+91" + this.state.phoneNo;
    const appVerifier = window.recaptchaVerifier;
    firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        this.setState({ NumberInput:false, OtpSend: true })
        console.log("OTP has been sent")
      }).catch((error) => {
        console.log("SMS not sent", error)
      });
  }

  onSubmitOTP = (e) =>{
    e.preventDefault()
    const code = this.state.otpcode
    window.confirmationResult.confirm(code).then((result) => {
      const accessToken = result.user._delegate.accessToken
      if (accessToken) {
        const phoneNo = this.state.phoneNo
        LoginService.login({phone: phoneNo})
        .then(response => {
              if (response.status === true) 
              {
                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("userId", response.users._id);
                localStorage.setItem("name", response.users.name);
                localStorage.setItem("phone", response.users.phone);
                this.props.history.push("/home");
                toast.success(response.message)
              }else{
                toast.error(response.message);
              }
        })
      }
    }).catch((error) => {
     console.log({OTP:error});
    });
  }

  render() {

    return (
      <>
        <div className='login'>
          <div className='container'>
            <div className='login_main'>
              <div className='row justify-content-center'>
                <div className='col-md-6'>
                  <div className='login_form'>
                    <div className='text-center' >
                      <img src={Logo} height="40" alt="logo" />
                    </div>
                    {this.state.NumberInput &&
                      <div>
                        <h2>Login</h2>
                        <form>
                          <div className="form-group mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label">Enter your Number</label>
                            <div id="sign-in-button"></div>
                            <input type="number" name='phoneNo' className="form-control" placeholder='Mobile number' required value={this.state.phoneNo} onChange={this.handleInputChanged.bind(this)} />
                          </div>
                          <button type="button" onClick={this.onSignInSubmit.bind(this)} className="btn btn-primary">Submit</button>
                        </form>
                      </div>
                    }
                    {this.state.OtpSend &&
                      <div >
                        <h2>Enter OTP</h2>
                        <form onSubmit={this.onSubmitOTP}>
                          <input type="number" className="form-control" name="otp" placeholder="OTP Number" required onChange={this.handleInputChangedOtp.bind(this)} />
                          <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                      </div>
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(Login)