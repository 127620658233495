import React from "react";
import Logo from "../../assets/images/footer_img.png";
import apple from "../../assets/images/apple.png";
import android from "../../assets/images/playstore.png";
import social_icon from "../../assets/images/social_icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div className="footer">
      <div className="row text-center align-items-center">
        <div className="col-md-6 col-lg-4 ">
          <div className="footer_logo">
            <img src={Logo} height="50" alt="logo" />
          </div>
        </div>
        <div className="col-md-6 col-lg-4">
          <div className="download_icon">
            <a href="#"><img src={apple} height="30" alt="phone" /></a>
            <a href="#"><img src={android} height="30" alt="phone" /></a>
          </div>
        </div>
        <div className="col-md-12 col-lg-4">
          <div className="social_icons">
            <ul>
              <li><a href="#"><FontAwesomeIcon icon={faFacebook} /></a></li>
              <li><a href="#"><FontAwesomeIcon icon={faInstagram} /></a></li>
              <li><a href="#"><FontAwesomeIcon icon={faTwitter} /></a></li>
              <li><a href="#"><FontAwesomeIcon icon={faLinkedin} /></a></li>
          </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
