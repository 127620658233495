import React, { Component } from 'react'
import HeaderComponent from "../../template/Header";
import AddTocartService from '../../../services/AddTocart'
import "./index.css"
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaMinus, FaPlus, FaTrash } from "react-icons/fa";
import swalAlert from 'sweetalert';

const userId = localStorage.getItem('userId')

export class Cart extends Component {

  constructor(props) {
    super(props)
    this.increment = this.increment.bind(this);
    this.decrement = this.decrement.bind(this);
    this.state = {
      countShopping: '',
      message: false,
      Total_price: '',
      Discount: '',
      orderAmount: '',
      payment_method: 'Cash on delivery',
      couponId: "",
      cartList: [],
      couponsList: [],
      selectCouponData: [],

    }
  }

  componentDidMount() {
    this.cartList()
  }

  cartList() {
    AddTocartService.CartList({ userId: userId })
      .then(response => {
        const initialValue = 0;
        const Total_price = response.cartList.reduce((accumulator, current) => accumulator + current.price, initialValue)
        this.setState({ countShopping: response.cartList.length, cartList: response.cartList, couponsList: response.couponsList, Total_price: Total_price })
      })
  }

  selectCoupon(data) {
    this.setState({ selectCouponData: data })
  }


  increment(i, data) {
    let { cartList } = this.state;
    let temp = cartList;
    let quantityTotal = temp[i].quantity + 1
    let totalPrice = quantityTotal * data.productId.price

    let formData = new FormData();
    formData.append("id", data._id);
    formData.append("userId", data.userId);
    formData.append("productId", data.productId._id);
    formData.append("quantity", quantityTotal);
    formData.append("price", totalPrice);
    AddTocartService.UpdateCart(formData)
      .then(response => {
        this.cartList()
      })

    // temp[i].quantity = temp[i].quantity + 1;
    // cartList = temp;
    // this.setState({ cartList: cartList })
  }

  decrement(i, data) {
    let { cartList } = this.state;
    let temp = cartList;
    let quantityTotal = temp[i].quantity + -1
    let totalPrice = quantityTotal * data.productId.price

    let formData = new FormData();
    formData.append("id", data._id);
    formData.append("userId", data.userId);
    formData.append("productId", data.productId._id);
    formData.append("quantity", quantityTotal);
    formData.append("price", totalPrice);
    AddTocartService.UpdateCart(formData)
      .then(response => {
        this.cartList()
      })
    // cartList = temp;
    // this.setState({ cartList: cartList })
  }


  removeCart(id) {
    AddTocartService.removeCart({ id: id })
      .then(response => {
        this.cartList()
        toast.success(response.message)
      })
  }

  ApplyCoupon(value) {
    const minAmount = value.minAmount
    let formData = new FormData();
    formData.append("couponId", value._id);
    formData.append("userId", userId);
    formData.append("coupon_code", value.coupon_code);
    formData.append("total", this.state.Total_price);
    AddTocartService.couponApplay(formData)
      .then(response => {
        if (response.status === true) {
          this.setState({ couponId: response.couponId, Discount: response.descount, orderAmount: response.totalValue })
          // swalAlert({
          //   title: "THANK YOU FOR YOUR ORDER!",
          //   text: (response.message),
          //   icon: "success",
          //   button: "Done!",
          // });
          swalAlert({
            icon: 'success',
            button: true,
            title: (response.message),
            showConfirmButton: false,
            timer: 3000,
          })
        } else {
          swalAlert({
            icon: 'error',
            button: true,
            title: (response.message),
            showConfirmButton: false,
            timer: 3000,
          })
        }
      })
  }

  MakePurchase() {
    let { cartList } = this.state;
    let orderList = []
    cartList.map(data => {
      orderList.push({ productId: data.productId._id, productName: data.productId.name, productImage: data.productId.image, quantity: data.quantity, price: data.price })
    })
    let formData = new FormData();
    if (this.state.couponId) {
      formData.append("couponId", this.state.couponId)
    }
    formData.append("userId", userId);
    formData.append("payment_method", this.state.payment_method);
    formData.append("orderAmount", this.state.orderAmount ? this.state.orderAmount : this.state.Total_price);
    formData.append("orderList", JSON.stringify(orderList));
    AddTocartService.MakePurchase(formData)
      .then(response => {
        if (response.status === true) {
          swalAlert({
            title: "THANK YOU FOR YOUR ORDER!",
            text: (response.message),
            // icon: "success",
            icon: 'https://cdn.dribbble.com/users/1782673/screenshots/7094387/media/36e8bc1fa63eff4348fb24193272dda1.gif',
            showConfirmButton: false,
            timer: 3000,
            button: (this.props.history.push("/car-accesories")),
          });

        } else {
          toast.success(response.message)
        }
      })

  }

  render() {
    const { cartList, couponsList } = this.state
    return (
      <>
        <HeaderComponent countShopping={this.state.countShopping} />
        <div className="container-fluid">
          <div className="row mt-2">
            <aside className="col-lg-9">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-borderless table-shopping-cart text-center">
                    <thead className="text-muted">
                      <tr className="small text-uppercase">
                        <th scope="col">Product</th>
                        <th scope="col" width="120">Quantity</th>
                        <th scope="col" width="120">Price</th>
                        <th scope="col" width="120">remove</th>
                        <th scope="col" className="text-right d-none d-md-block" width="200"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartList.map((data, i) => {
                        return (
                          <tr>
                            <td>
                              <figure className="itemside align-items-center">
                                <div className="aside"><img src={process.env.REACT_APP_STORAGE_URL + data.productId.image} className="img-sm" alt={data.productId.name} /></div>
                                <figcaption className="info">
                                  <p className="text-muted small"> Product: {data.productId.name}<br /> Description : {data.productId.description} </p>
                                </figcaption>
                              </figure>
                            </td>
                            <td className='qty_btn'>
                              <button type="button" className="btn btn-light"> <FaPlus onClick={() => this.increment(i, data)} /> </button>
                              <span > {data.quantity} </span>
                              <button type="button" className="btn btn-light"> <FaMinus onClick={() => this.decrement(i, data)} /> </button>
                            </td>
                            <td>
                              <div className="price-wrap"> <var className="price">{data.price}</var></div>
                            </td>
                            <td className='qty_btn'>
                              <button type="button" className="btn btn-danger" onClick={() => this.removeCart(data._id)}><FaTrash /></button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </aside>
            <aside className="col-lg-3">
              <div className="card mb-3">
                <div className="card-body">
                  <form>
                    <div className="form-group"> <label>Have coupon?</label> <b data-bs-toggle="modal" data-bs-target="#exampleModal">More Coupon</b>
                      <div className="input-group"> <input type="text" className="form-control coupon" name="" placeholder="Coupon code" value={this.state.selectCouponData.coupon_code} /> <span className="input-group-append">
                        <button type='button' className="btn btn-primary btn-apply coupon" onClick={() => this.ApplyCoupon(this.state.selectCouponData)}>Apply</button> </span> </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <dl className="dlist-align">
                    <dt>Payment:</dt>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={this.PaymentHandle} />
                      <label className="form-check-label" for="flexCheckDefault">
                        Cash on delivery
                      </label>
                    </div>
                  </dl>
                  <hr />
                  <dl className="dlist-align">
                    <dt>Total price:</dt>
                    <dd className="text-right ml-3">{this.state.Total_price}</dd>
                  </dl>
                  <dl className="dlist-align">
                    <dt>Discount:</dt>
                    {/* {data.type === "Rs" ? (<div>{data.type} : {data.descount} </div>) : (<div>{data.type} : {data.descount} </div>)} */}
                    <dd className="text-right text-danger ml-3">{this.state.Discount}</dd>
                  </dl>
                  <dl className="dlist-align">
                    <dt>Total:</dt>
                    <dd className="text-right text-dark b ml-3"><strong>{this.state.orderAmount ? this.state.orderAmount : this.state.Total_price}</strong></dd>
                  </dl>
                  <hr /><button className="btn btn-out btn-success btn-square btn-main mt-2" data-abc="true" onClick={() => this.MakePurchase()}>Make Purchase</button>
                </div>
              </div>
            </aside>
          </div>
        </div>

        {/* <!-- Modal --> */}

        <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel"> Coupon code</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              {couponsList.map(data => {
                return (
                  <ol className="list-group">
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2">
                        <div className="fw-bold">couponName : {data.couponName}</div>
                        <div className="fw-bold">Code :<b className='text-danger'>{data.coupon_code}</b></div> <div className='fw-bold'> Description : {data.description}</div> </div>
                      {data.type === "Rs" ? (<div>{data.type} : {data.descount} </div>) : (<div>{data.type} : {data.descount} </div>)}
                      <span className="badge bg-primary rounded-pill">{data.UsesPerCoupon}</span>
                      <button type="button" className="btn btn-success" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.selectCoupon(data)} >Select</button>
                    </li>
                  </ol>
                )
              })}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(Cart)