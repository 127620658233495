import React, { Component } from 'react'
import img1 from "../../assets/images/visited_categories/img_1.png";
import img2 from "../../assets/images/visited_categories/img_2.png";
import img3 from "../../assets/images/visited_categories/img_3.png";
import img4 from "../../assets/images/visited_categories/img_4.png";
import service_img from "../../assets/images/car_service_provider/img_3.png";
import wheel from "../../assets/images/wheel.png";
import carWashServices from "../../services/Services";
import DownloadApp from "../pages/DownloadApp";
import car from "../../assets/images/home/car_banner.png";
import Header from "../template/Header";
import Footer from "../template/Footer";
import { NavLink } from "react-router-dom";


class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
        isshow:false,
        carWash: [],
        carService:[]
    };
  }

  componentDidMount() {
    this.carWashServices();
    this.CarServicesProvider()
  }

  carWashServices() {
    try {
      carWashServices.washProvider().then((response) => {
        this.setState({carWash: response.data,isshow:true,  });
      });
    } catch (error) {
      console.log(error);
    }
  }

  CarServicesProvider() {
    try {
      carWashServices.washProvider().then((response) => {
        this.setState({carService: response.data,isshow:true,  });
      });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { carWash,carService } = this.state

const carCategories = [
  { img: img1, title: "Car Service", providers: "12,568 Providers" },
  { img: img2, title: "Car Buy/Sale", providers: "11,756 Providers" },
  { img: img3, title: "Car Wash", providers: "10,112 Providers" },
  { img: img4, title: "Car Accessories", providers: "12,568 Providers" },
]

    return (
      <>
      <Header />

      <div className="home-main">
        <img src={car} alt="car" className="car_img" />

        <div className="circle_1">
          <div className="circle_inner">
            <img src={wheel} className="w-100" />
            <NavLink className="menu_link" to={"/car-accesories"}>
              ACCESSORIES
            </NavLink>
          </div>
        </div>

        <div className="circle_2">
          <div className="circle_inner">
            <img src={wheel} className="w-100" />
            <NavLink className="menu_link" to={"/car-provider"}>
              CAR SALE
            </NavLink>
          </div>
        </div>

        <div className="circle_3">
          <div className="circle_inner">
            <img src={wheel} className="w-100" />
            <NavLink className="menu_link" to={"/car-provider"}>
              CAR BUY
            </NavLink>
          </div>
        </div>

        <div className="circle_4">
          <div className="circle_inner">
            <img src={wheel} className="w-100" />
            <NavLink className="menu_link" to={"/car-wash"}>
              CAR WASH
            </NavLink>
          </div>
        </div>

        <div className="circle_5">
          <div className="circle_inner">
            <img src={wheel} className="w-100" />
            <NavLink className="menu_link" to={"/car-service"}>
              CAR SERVICE
            </NavLink>
          </div>
        </div>
      </div>

      {/* VisitedCategories */}

      <section className="sec_main" id="caraccessories">
        <div className="container">
          <div className="visit_categories">
            <div className="sec_title">
              <h4>Most Visited Categories</h4>
            </div>

            <div className="sec_subtitle">
              <p>
                Professional car service,Car repair,Get pick up and
                drop,Accessories,Car wash, Car buy or sale,in one stop solution
                in your city of lights.
              </p>
            </div>

            <div className="row">
              {carCategories.map((data, id) => {
                return (
                  <div className="col-md-6 col-lg-3" key={id}>
                    <div className="list_box">
                      <img src={data.img} alt="location" />
                      <div className="list_text pt-3">
                        <h5>{data.title}</h5>
                        <p>{data.providers}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      {/* car_service */}

      <section className="sec_main" id="carservice">
        <div className="container">
          <div>
            <div className="sec_title">
              <h4>Car Services Provider</h4>
            </div>

            <div className="sec_subtitle">
              <p>
                Find the best service packages for your car.Select from a wide
                range of car services,from general service, accidental repair to
                custom repairs,etc...
              </p>
            </div>

            <div className="row">
              {carService.slice(0, 8).map((data, id) => {
                return (
                  <div className="col-md-6 col-lg-3" key={id}>
                    <div className="list_box_cars shadow-lg bg-body rounded">
                      {data.photo_reference ? (
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            data.photo_reference
                          }
                          alt="img"
                          className="w-100"
                        />
                      ) : (
                        <img src={service_img} alt="img" className="w-100" />
                      )}
                      <div className="list_box_text pt-4">
                        <h5>{data.name}</h5>
                        <p>{data.area}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div>
              <NavLink
                className="btn btn-primary"
                to="/car-service"
                role="button"
              >
                Explore more
              </NavLink>
            </div>
          </div>
        </div>
      </section>

      {/* car wash provider */}

      <section className="sec_main" id="carwash">
        <div className="container">
          <div className="car_wash_provider">
            <div className="sec_title">
              <h4>Car Wash Provider</h4>
            </div>

            <div className="sec_subtitle">
              <p>
                A car wash, carwash, or auto wash is a facility used to clean
                the exterior, and in some cases the interior of motor vehicles.
                Car washes can be self-service, full-service, or fully
                automated.
              </p>
            </div>

            <div className="row">
              {carWash.slice(0, 4).map((data, id) => {
                return (
                  <div className="col-md-6 col-lg-3" key={id}>
                    <div className="list_box_cars_wash shadow-lg bg-body rounded">
                      {data.photo_reference ? (
                        <img
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            data.photo_reference
                          }
                          alt="img"
                          className="w-100"
                        />
                      ) : (
                        <img src={service_img} alt="img" className="w-100 " />
                      )}
                      <div className="list_car_wash_text pt-3">
                        <h5>{data.name}</h5>
                        <p className="city_area">{data.area}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div>
              <NavLink className="btn btn-primary" to="/car-wash" role="button">
                Explore more
              </NavLink>
            </div>
          </div>
        </div>
      </section>

      <DownloadApp />
      <Footer />
    </>
    )
  }
}
export default Home