import React from "react";
import phone from "../../assets/images/app.png";
import apple from "../../assets/images/apple.png";
import android from "../../assets/images/playstore.png";

const DownloadApp = () => {
  return (
    <>
      <section className="sec_main">
        <div className="container">
          <div className="download_app">
            <div className="sec_title pb-4">
              <h4>Download Urbankars App</h4>
            </div>

            <div className="row align-items-center pt-4">
              <div className="col-md-6 col-lg-4">
                <div className="sec_subtitle">
                  <img src={phone} alt="phone" />
                </div>
              </div>

              <div className="col-md-6 col-lg-8">
                <div className="download_app_text">
                  <h5 className="mb-5">
                    <strong>
                      Choose and book a seamless car service experience and get
                      upto Rs 750 off with the Urbankars App.
                    </strong>
                  </h5>
                </div>

                <div className="download_app_img">
                  <img src={apple} height="40px" alt="phone" />
                  <img src={android} height="40px" alt="phone" />
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DownloadApp;
