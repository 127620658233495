import React, { Component } from 'react'
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CarsService from "./views/pages/CarsService";
import Home from "./views/home/Home"
import CarProvider from "./views/pages/CarProvider";
import CarWashProvider from "./views/pages/CarWashProvider";
import CarAccessories from "./views/pages/CarAccessories";
// import Footer from "./views/template/Footer";
import Welcome from "./views/template/Welcome";
import Cart from "./views/pages/Cart";
import LoginComponent from "./views/pages/login";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import withRouter from './views/pages/withRouter';


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      AccessToken: ''
    }
  }

  componentDidMount() {
    let token = localStorage.getItem('isToken')
    if (token) {
      this.setState("token")
    }
  }

  render() {
    return (
      <>
        <div className="App">

          {/* <Welcome /> */}
          <Router>
            <Switch>
              <Route exact path="/">
                <Welcome />
              </Route>

              <Route path="/home">
                <Home />
              </Route>

              <Route path="/car-service">
                <CarsService />
              </Route>

              <Route path="/car-provider">
                <CarProvider />
              </Route>

              <Route path="/car-wash">
                <CarWashProvider />
              </Route>

              <Route path="/car-accesories">
                <CarAccessories />
              </Route>

              <Route path="/cart">
                <Cart />
              </Route>

              <Route path="/login">
                <LoginComponent />
              </Route>

            </Switch>
          </Router>
          {/* <Footer />   */}
        </div>

        <ToastContainer
          autoClose={1000}
          hideProgressBar={true}
        />

      </>
    )
  }
}

export default App