import Call from "./Call";

const login = async (data) => {
    let d = await Call({
      path: "api/users/userlogin",
      method: "post",
      data,
    });
    return d;
  };


  export default { login }