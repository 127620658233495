import Call from "./Call";

const getData = async () => {
    let d = await Call({
      path: "api/admin/carAccessories",
      method: "GET",
    });
    return d;
  };


  export default { getData }